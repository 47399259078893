.about-main {
  display: flex;
  padding: 2rem 8rem;
}

.about-title {
  color: #0086f8;
  padding-top: 4rem;
}

.about-main ul {
  margin-top: 1rem;
  font-size: 1.5rem;
  text-align: left;
}

.about-main ul > li {
  margin-bottom: 1rem;
}

.about-main img {
  max-width: 650px;
}

.about-subtitle {
  color: #0086f8;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  font-size: 2.4rem;
}

.about-main > div {
  padding: 1rem;
}

.about-main p {
  margin-top: 1rem;
  text-align: left;
  font-size: 1.5rem;
}

.cards {
  display: flex;
  justify-content: center;
}
