.card {
  width: 20%;
  flex: 0 0 18.5rem;
  border-radius: 20px;
  padding: 1.6rem;
  margin: 0 0.8rem;
  box-shadow: 0 8px 15px 0 rgb(59 105 142 / 15%);
  transition: box-shadow .2s ease-in-out;
  text-align: left;
  margin-bottom: 4rem;
  margin: 2rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 20rem;
  justify-content: center;
  align-items: center;
}

.card:hover {
  box-shadow: 0 12px 20px 0 rgb(59 105 142 / 33%);
}

.value-img-container {
  border: 1px solid lightgrey;
  border-radius: 0.5rem;
  height: 4.8rem;
  width: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.value-img {
  height: 3rem;
  width: 3rem;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.card-text {
  font-size: 1rem;
}