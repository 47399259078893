.brand-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.brand-card {
  max-width: 70vw;
  margin-top: 5rem;
  box-shadow: 0 8px 15px 0 rgb(59 105 142 / 15%);
  transition: box-shadow .2s ease-in-out;
  padding: 2rem;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.brand-card:hover {
  box-shadow: 0 12px 20px 0 rgb(59 105 142 / 33%);
}

.our-brands {
  color: #0086f8;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.mini-cards {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.image-container {
  transition: all 0.3s ease-in-out;
  margin: 1rem;
  width: 12rem;
}

.brand-image {
  max-width: 100%;
}

.image-container:hover {
  width: 10rem;
}

.fa-brands {
  margin: 1rem;
}

.fa-brands:hover {
  opacity: 70%;
}