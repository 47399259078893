.footer {
  background-color: #252122;
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.footer-logo {
  margin-left: 3rem;
  width: 8rem;
}

.footer-text {
  margin-right: 2rem;
}

.nav-links {
  font-size: 1.3rem;
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.nav-links:hover {
  color: #0086f8;
}

.nav-links.active {
  color: #0086f8;
}

.social-links {
  font-size: 2rem;
  color: #fff;
  padding: 0.5rem 1rem;
}

.social-links:hover {
  color: #0086f8;
}

.footer-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
}