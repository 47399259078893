.box {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.my-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 50vw;
  align-items: center;
}

.form-field {
  width: 100%;
}

.action-button {
  width: 8rem;
}