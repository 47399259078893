:root {
  --main-blue: #0086f8;
  --main-black: #252122;
}

.navbar {
  position: fixed;
  background-color: #252122;
  box-shadow: 0px 2px 20px -10px rgb(0 0 0 / 25%);
  height: 90px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  z-index: 20;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.nav-logo {
  margin-left: 20px;
  cursor: pointer;
  width: 20rem;
  padding-right: 2rem;
}

.logo-img {
  width: 20rem;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #0086f8;
}

.nav-item .active {
  color: #0086f8;
  border: 1px solid #0086f8;
  border-radius: 5px;
}

.nav-icon {
  display: none;
  margin-right: 1.5rem;
}

.nav-icon:hover {
  opacity: 80%;
}

.lang-switch {
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.language {
  color: white;
  font-size: 2rem;
  margin-right: 0.6rem;
}

.lang-choice {
  margin-right: 0.6rem;
  cursor: pointer;
}

button.lang-choice {
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  font-size: inherit;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 1090px) {
  .logo-img {
    max-width: 70vw;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #252122;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item .active {
    color: #0086f8;
    border: none;
  }

  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #0086f8;
  }

  .lang-switch {
    padding: 1.5rem;
  }
}