.question {

  padding: 1rem;

  display: flex;
  justify-content: center;
}

.question-title {
  font-size: 1.5rem;
}

.expand {
  align-self: flex-start;
  justify-self: end;
  margin-left: 1rem;
  font-size: 2rem;
  color: rgb(0, 0, 0);
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	outline: inherit;
}

.answer-text {
  font-size: 1.5rem;
}