h2 {
  color: #0086f8;
}

.brands-page {
  background-color: #e9eff1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brands-title {
  color: #0086f8;
  padding-top: 4rem;
}

.brands-description {
  font-size: 1.2rem;
  margin-top: 2rem;
}

.brands-container-b {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2rem 10rem;
}

.image-container-b {
  width: 15rem;
  transition: all 0.3s ease-in-out;
  margin: 1rem;
}

.brand-image-b {
  max-width: 100%;
}

.image-container-b:hover {
  width: 12rem;
}

.service-card-container {
  display: flex;
  justify-content: center;
  padding: 3rem;
}