.faq {
  background-color: #f3f4fe;
}

.faq-title {
  font-size: 2rem;
  padding-top: 5rem;
  padding-bottom: 1rem;
}

.faq-description {
  font-size: 1.8rem;
  margin-top: 2rem;
}

.questions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem;
}

.question-mark {
  background-color: #0086f8;
  color: white;
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  font-size: 2.5rem;
  font-weight: bold;
  margin: 1rem;
}