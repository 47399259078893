
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: white;
}

.pages {
  color: #252122;
  text-align: center;
  font-size: 3.5rem;
  margin-top: 5rem;
}