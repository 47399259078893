.banner-div {
  margin-top: 80px;
  padding-bottom: 5rem;
}

.home-image {
  max-width: 100%;
}

.main-description {
  font-size: 3rem;
}

.description-text {
  font-size: 2rem;
}

.benefits {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 2rem 8rem;
}

.second-description {
  font-size: 2rem;
  margin-top: 2rem;
}

.second-description-text {
  margin-top: 3rem;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
}

.description-paragraph {
  width: 40%;
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 2rem;
}

.divider {
  padding: 5rem 0 5rem 0;
  background-color: #e9eff1;
  box-shadow: inset 3px 0 3px 0 #e9eff1, inset -3px 0 3px 0 #e9eff1, inset 0 6px 11px -4px rgb(36 72 101 / 25%), inset 0 -6px 11px -4px rgb(36 72 101 / 25%);
}

.divider-img {
  height: 5rem;
  width: 5rem;
}

.divider-title {
  font-size: 2rem;
}

.action-button {
  margin: 1rem !important;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  padding: 1rem;
  background-color: #0086f8;
  color: white;
  border-radius: 0.3rem;
  font-size: 1rem;
  outline: inherit;
  border: 1px solid #0086f8;
  font-weight: bold;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
}

.action-button:hover {
  color: white;
  background-color: #252122;
  border-color: #252122;
  opacity: 80%;
}

.border-button {
  margin: 1rem !important;
  cursor: pointer;
  background-color: transparent;
  color: #0086f8;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  border-radius: 0.3rem;
  position: relative;
  overflow: hidden;
  padding: 1rem;
  font-size: 1rem;
  font-weight: bold;
  outline: inherit;
  border: 1px solid #0086f8;
}

.border-button:hover {
  color: white;
  background-color: #252122;
  border-color: #252122;
  opacity: 80%;
}